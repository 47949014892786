import { defineStore } from "pinia";
import { User } from "../types";

export const useUserStore = defineStore("user", {
  state: (): {
    user: User | null;
    isLoggedIn: boolean;
    changeEmail: boolean;
    verify:boolean;
  } => ({
    user: null,
    isLoggedIn: false,
    changeEmail: false,
    verify:false
  }),
  actions: {
    clearUser() {
      this.user = null;
      this.isLoggedIn = false;
    },
    setUser(user: User) {
      this.user = user;
      this.isLoggedIn = true;
    },
    changeEmailUser(value:boolean) {
      this.changeEmail = value;
    },
    setUserVerify(value) {
      this.verify = value
    },
  },
});
